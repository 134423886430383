import React from "react";
import "./about.css";
import ME from "../../assets/look at meeeee.png";

import { FaAward } from "react-icons/fa";
import { FaNetworkWired } from "react-icons/fa";
import { BsConeStriped } from "react-icons/bs";

const About = () => {
  return (
    <section id="about">
      <h5 className="text">Get to know</h5>
      <h2>ME</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card ">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2 Months Working</small>
            </article>

            <article className="about__card ">
              <FaNetworkWired className="about__icon" />
              <h5>Projects</h5>
              <small>1 compeleted</small>
              <br />
              <small>More loading...</small>
            </article>

            <article className="about__card ">
              <BsConeStriped className="about__icon" />
              <h5>Fun Fact</h5>
              <small></small>
            </article>
          </div>

          <p>
            I'm Marie Ezoua, a Computer Science and Math major at Georgia
            Military College. I started as a self-taught python programmer and
            decided to pursue programming in school.
          </p>

          <a href="#contact" className="btn btn-primary">
            Lets talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
