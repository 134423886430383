import React from "react";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineGithub } from "react-icons/ai";
import { SiHackerrank } from "react-icons/si";

const SocialIcons = () => {
  return (
    <div className="social__Icons">
      <a href="https://www.linkedin.com/in/marie-ezoua" target="_blank">
        <AiFillLinkedin />
      </a>
      <a href="https://twitter.com/marieezouaa" target="_blank">
        <AiOutlineTwitter />
      </a>
      <a href="https://github.com/Marieezouaa" target="_blank">
        <AiOutlineGithub />
      </a>
      <a href="https://www.hackerrank.com/ezouacode" target="_blank">
        <SiHackerrank />
      </a>
    </div>
  );
};

export default SocialIcons;
