import React from "react";
import "./experience.css";

import { GoVerified } from "react-icons/go";

const Experience = () => {
  return (
    <section id="experience">
      <h5 className="exH5">My Skills</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__programming">
          <h3>Software Development</h3>
          <div className="experience__concent">
            <article className="experience__details">
              <GoVerified className="experience__details-icon" />
              <div>
                <h4>Java</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>

            <article className="experience__details">
              <GoVerified className="experience__details-icon" />
              <div>
                <h4>C#</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>

            <article className="experience__details">
              <GoVerified className="experience__details-icon" />
              <div>
                <h4>Python</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__webdev">
          <h3>Web Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <GoVerified className="experience__details-icon" />

              <div>
                <h4>HTML</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>

            <article className="experience__details">
              <GoVerified className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Proficient</small>
              </div>
            </article>

            {/* <article className="experience__details">
              <GoVerified className="experience__details-icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article> */}

            <article className="experience__details">
              <GoVerified className="experience__details-icon" />

              <div>
                <h4>React</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>

            <article className="experience__details">
              <GoVerified className="experience__details-icon" />
              <div>
                <h4>Node.Js</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>

            <article className="experience__details">
              <GoVerified className="experience__details-icon" />

              <div>
                <h4>Java Script</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
