import React from "react";
import "./contact.css";
import { GrMail } from "react-icons/gr";
import { BsLinkedin } from "react-icons/bs";
import { SiDiscord } from "react-icons/si";
import { useRef } from "react";

import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_8e4s7pc",
      "template_onuj04n",
      form.current,
      "6axTqU2DSSFxapnNF"
    );
    e.target.reset();
  };

  return (
    <section id="contact">
      <h5 className="conH5">Get In Touch</h5>
      <h2>Contact ME</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <GrMail className="contact__option-icon" />

            <h4>Email</h4>
            <h5>ezouaamarie@gmail.com</h5>
            <a href="mailto:ezouaamarie@gmail.com">Send a message</a>
          </article>

          <article className="contact__option">
            <BsLinkedin className="contact__option-icon" />

            <h4>LinkedIn</h4>
            <h5>Marie Ezoua</h5>
            <a href="https://www.linkedin.com/in/marie-ezoua/" target="_blank">
              Send a message
            </a>
          </article>

          <article className="contact__option">
            <SiDiscord className="contact__option-icon" />

            <h4>Discord</h4>
            <h5>Web Dwellers Discord</h5>
            <a href="https://discord.gg/qkNJmgvayw" target="_blank">
              Join my Discord
            </a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Full Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
