import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/001.jpg";
import IMG2 from "../../assets/003.png";
import IMG3 from "../../assets/003.webp";

import IMG4 from "../../assets/004.webp";
import IMG5 from "../../assets/005.webp";
import IMG6 from "../../assets/006.webp";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "User Dashboard",
    github: "https://github.com/Marieezouaa/userDashBoard",
    demo: "https://codepen.io/Marieezouaa/pen/zYjXWLx",
  },
  {
    id: 3,
    image: IMG2,
    title: "Upcoming Project",
    github: "https://github.com/Marieezouaa",
    demo: "https://github.com/Marieezouaa",
  },
  {
    id: 2,
    image: IMG3,
    title: "Upcoming Project",
    github: "https://github.com/Marieezouaa",
    demo: "https://github.com/Marieezouaa",
  },

  {
    id: 4,
    image: IMG4,
    title: "Upcoming Project",
    github: "https://github.com/Marieezouaa",
    demo: "https://github.com/Marieezouaa",
  },
  {
    id: 5,
    image: IMG5,
    title: "Upcoming Project",
    github: "https://github.com/Marieezouaa",
    demo: "https://github.com/Marieezouaa",
  },
  {
    id: 6,
    image: IMG6,
    title: "Upcoming Project",
    github: "https://github.com/Marieezouaa",
    demo: "https://github.com/Marieezouaa",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a
                  href="https://github.com/Marieezouaa"
                  className="btn"
                  target="_blank"
                >
                  Github
                </a>
                <a href={demo} className="btn btn-primary" target="_blank">
                  live Demo
                </a>
                {/*insert future github project here */}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
